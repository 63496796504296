<template>
  <div class="church-ministry template-1">
    <header class="page-header container">
      <h1>{{ mrpTopic.name }}</h1>
      <BannerAid
        :bannerLink="`mrp-topic-detail|${mrpSelectedProgramId}|${mrpSelectedCategory}|${mrpSelectedTopicId}`"
      />
    </header>
    <b-container class="page-body">
      <b-row class="mb-5">
        <b-col sm="6" class="topic-col">
          <h4 class="mr-3">{{ mrpTopic.topicSelectText }}</h4>
          <b-form-select
            id="topic-select"
            name="topic-select"
            :title="translations.tcTopic"
            v-model="selectedTopicId"
            :options="topicsOptions"
            class="form-control"
            @change="topicChange()"
          ></b-form-select>
        </b-col>
        <b-col sm="6" class="search-col">
          <search-form :i18n="translations.components" />
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <p>
            {{ mrpTopic.description }}
          </p>
        </b-col>
      </b-row>
      <b-row v-if="mrpTopic.videoListing">
        <b-col sm="12" class="video-col">
          <div class="embed-responsive embed-responsive-16by9" id="videoPlayerContainer">
            <vueVimeoPlayer
              ref="player"
              :video-id="videoID"
              :player-height="720"
              :autoplay="false"
              @ready="this.onReady"
            ></vueVimeoPlayer>
          </div>
        </b-col>
      </b-row>
      <b-row class="tile-row">
        <b-col sm="6" xs="12" class="tile-col mt-5">
          <card
            :category="`videos`"
            :items="videos"
            :fields="videosFields"
            :cardTitle="mrpTopic.videoTitle"
            :showLinkButton="false"
            :sortOptions="videoSortOptions"
            :sortBy="`VideoUpdated`"
            :translations="translations"
            @sort_changed="sortChanged($event)"
            @name_link_click="nameLinkClick($event)"
            class="tile-col-card"
          >
          </card>
        </b-col>
        <b-col sm="6" xs="12" class="tile-col mt-5">
          <card
            :category="`resources`"
            :items="resources"
            :fields="resourcesFields"
            :cardTitle="mrpTopic.resourceTitle"
            :showLinkButton="false"
            :sortOptions="resourceSortOptions"
            :sortBy="`ResourceUpdated`"
            :translations="translations"
            @sort_changed="sortChanged($event)"
            @name_link_click="nameLinkClick($event)"
            class="tile-col-card"
          >
          </card>
        </b-col>
      </b-row>
    </b-container>
    <footer class="page-footer"></footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import BannerAid from '@/components/BannerAid.vue'
import Card from './CardMRPProgram.vue'
import searchForm from './SearchForm.vue'
import { translationMixin } from '@/mixins/translationMixin'
import { vueVimeoPlayer } from 'vue-vimeo-player'

export default {
  name: 'mrp-topic',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      itemsDisplayCount: 999,
      videoID: '288800270',
      defaultVideoLink: 'https://player.vimeo.com/video/288800270',
      playerReady: false,
      search: '',
      selectedTopicId: null,
      resourceSort: 'ResourceUpdated',
      resourceSortOptions: [
        {
          value: 'ResourceName',
          text: '', // 'Name'
        },
        {
          value: 'ResourceUpdated',
          text: '', // 'Most Recent'
        },
      ],
      resourcesFields: [
        {
          key: 'icon',
          label: 'icon',
          thStyle: {
            display: 'none',
          },
          tdClass: 'mrpIconClass',
        },
        {
          key: 'nameLink',
          label: 'nameLink',
          thStyle: {
            display: 'none',
          },
        },
        {
          key: 'download_link',
          label: 'download_link',
          thStyle: {
            display: 'none',
          },
          tdClass: 'mrpDownloadClass',
        },
      ],
      videoSort: 'VideoUpdated',
      videoSortOptions: [
        {
          value: 'VideoName',
          text: '', // 'Name'
        },
        {
          value: 'VideoUpdated',
          text: '', // 'Most Recent'
        },
      ],
      videosFields: [
        {
          key: 'icon',
          label: 'icon',
          thStyle: {
            display: 'none',
          },
          tdClass: 'mrpIconClass',
        },
        {
          key: 'nameLink',
          label: 'nameLink',
          thStyle: {
            display: 'none',
          },
        },
        {
          key: 'download_link',
          label: 'download_link',
          thStyle: {
            display: 'none',
          },
          tdClass: 'mrpDownloadClass',
        },
      ],
    }
  },
  components: {
    BannerAid: BannerAid,
    card: Card,
    SearchForm: searchForm,
    vueVimeoPlayer: vueVimeoPlayer,
  },
  methods: {
    ...mapActions({
      loadMRPProgramInfo: 'mrp/loadMRPProgramInfo',
      loadMRPTopicInfo: 'mrp/loadMRPTopicInfo',
      setLoadingStatus: 'menu/setLoadingStatus',
      setMRPSearchVideoId: 'mrp/setMRPSearchVideoId',
      setMRPSelectedTopicId: 'mrp/setMRPSelectedTopicId',
      setMRPSelectedVideoId: 'mrp/setMRPSelectedVideoId',
    }),
    createTitle(code) {
      return {
        ax: 'Auxiliary',
        cm: 'Church Ministry',
        co: 'Officer',
        gc: 'GideonCard',
        ff: 'Faith Fund',
        mb: 'Membership',
        of: 'Officer',
        pw: 'Prayer & Personal Witnessing',
        sd: 'Scripture Distribution',
        st: 'Officer',
        xx: 'Program',
      }[code || 'xx']
    },
    loadTranslations() {
      this.resourceSortOptions = [
        {
          value: 'ResourceName',
          text: this.translations.tcName,
        },
        {
          value: 'ResourceUpdated',
          text: this.translations.tcMostRecent,
        },
      ]
      this.videoSortOptions = [
        {
          value: 'VideoName',
          text: this.translations.tcName,
        },
        {
          value: 'VideoUpdated',
          text: this.translations.tcMostRecent,
        },
      ]
    },
    async pageLoad() {
      await Promise.all([
        this.setLoadingStatus(true),
        this.loadMRPTopicInfo({
          lang: this.prefCulture,
          topicid: this.mrpSelectedTopicId,
        }),
      ]).then(() => {
        this.selectedTopicId = this.mrpSelectedTopicId
        if (this.mrpTopic.videoListing.length > 0) {
          let watchVideoIndex = 0
          if (this.mrpSearchVideoId.length > 0) {
            const vidIndex = this.videos.map((e) => e.itemId).indexOf(this.mrpSearchVideoId)
            watchVideoIndex = vidIndex >= 0 ? vidIndex : 0
            this.setMRPSearchVideoId('')
          }
          this.showVideo(
            this.videos[watchVideoIndex].video_link,
            this.videos[watchVideoIndex].download_link,
            this.videos[watchVideoIndex].nameLink
          )
        } else {
          this.showVideo(this.defaultVideoLink, '', '')
        }
        this.setLoadingStatus(false)
      })
    },
    nameLinkClick(data) {
      switch (data.category) {
        case 'resources':
          window.open(data.link_url, '_blank')
          break
        case 'videos':
          this.setMRPSelectedVideoId(data.itemId)
          this.showVideo(data.video_link, data.download_link, data.nameLink)
          break
        default:
          break
      }
    },
    sortChanged(data) {
      switch (data.programCategory) {
        case 'topics':
          this.topicSort = data.sortBy
          break
        case 'resources':
          this.resourceSort = data.sortBy
          break
        case 'videos':
          this.videoSort = data.sortBy
          break
        case 'links':
          this.linkSort = data.sortBy
          break
        default:
          break
      }
    },
    topicChange() {
      setTimeout(() => {
        // this is to give the change event a tick to update the value
        this.setMRPSelectedTopicId(this.selectedTopicId)
        this.pageLoad()
      }, 0)
    },
    showVideo(fileId, fileDownloadLink, filename) {
      this.videoID = fileId.replace('https://player.vimeo.com/video/', '')
      this.videoFileDownloadLink = fileDownloadLink
      this.videoName = filename
      this.$refs.player.pause()
      this.displayVideo = 'block'
      var elmnt = document.getElementById('videoPlayerContainer')
      elmnt.scrollIntoView()
    },
    onReady() {
      this.playerReady = true
    },
    sortTheArray(listingArray, sortOn, makeDesc) {
      let s1 = makeDesc ? -1 : 1
      let s2 = makeDesc ? 1 : -1
      return listingArray.sort((a, b) => (a[sortOn] > b[sortOn] ? s1 : s2))
    },
  },
  computed: {
    ...mapGetters({
      mrpProgramInfo: 'mrp/mrpProgramInfo',
      mrpSearchVideoId: 'mrp/mrpSearchVideoId',
      mrpSelectedCategory: 'mrp/mrpSelectedCategory',
      mrpSelectedProgramId: 'mrp/mrpSelectedProgramId',
      mrpSelectedTopicId: 'mrp/mrpSelectedTopicId',
      mrpSelectedVideoId: 'mrp/mrpSelectedVideoId',
      mrpTopic: 'mrp/mrpTopic',
      prefCulture: 'user/userPreferredCulture',
    }),
    videos() {
      this.sortTheArray(this.mrpTopic.videoListing, this.videoSort, this.videoSort.includes('Updated'))
      let newVideoList = this.mrpTopic.videoListing.map((v) => {
        return {
          category: 'videos',
          itemId: v.VideoId,
          nameLink: v.VideoName,
          icon: v.VideoThumbnailId,
          icon_alt_text: 'Click to play',
          video_link: v.VideoLink,
          downloadIcon: '479F189B-353E-48B3-817E-FC784AC59C1B', //GUID in sitecore for download icon
          download_link: v.VideoDownload,
          link_url: '',
          updated: v.VideoUpdated,
        }
      })
      return newVideoList.slice(0, this.itemsDisplayCount)
    },
    resources() {
      this.sortTheArray(this.mrpTopic.resourceListing, this.resourceSort, this.resourceSort.includes('Updated'))
      let newResourceList = this.mrpTopic.resourceListing.map((r) => {
        return {
          category: 'resources',
          itemId: r.ResourceId,
          nameLink: r.ResourceName,
          icon: r.ResourceThumbnailId,
          icon_alt_text: r.ResourceType,
          downloadIcon: '479F189B-353E-48B3-817E-FC784AC59C1B', //GUID in sitecore for download icon
          download_link: r.ResourceLink,
          link_url: r.ResourceLink,
          updated: r.ResourceUpdated,
        }
      })

      return newResourceList.slice(0, this.itemsDisplayCount)
    },
    topicsOptions() {
      this.sortTheArray(this.mrpProgramInfo.topicListing, 'TopicName', false)
      let newTopicsOptionsList = this.mrpProgramInfo.topicListing.map((t) => {
        return {
          value: t.TopicId,
          text: t.TopicName,
        }
      })

      return newTopicsOptionsList
    },
  },
  async created() {
    //need a selected mrp program and topic
    if (!this.mrpSelectedProgramId) {
      this.$router.push('/resources/media-portal/')
    } else if (!this.mrpSelectedTopicId) {
      this.$router.push('/resources/media-portal/program')
    }
    await Promise.all([
      this.getViewTranslations(),
      this.getComponentTranslations('mrp-search-form'),
      this.pageLoad(),
    ]).then((results) => {
      const componentTranslations = results[1]
      this.$set(this.translations, 'components', componentTranslations)

      this.loadTranslations()
    })
  },
}
</script>

<style lang="scss" scoped>
/* eslint-disable */
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';
.page {
  .page-body {
    @include breakpoint(sm) {
      margin-top: 3rem;
    }
    h4 {
      margin-bottom: 10px;
    }
  }
}
.topic-col {
  h4 {
    display: inline-block;
  }
  select.form-control {
    max-width: 320px;
    padding: 6px 28px 6px 12px;
  }
}
.video-col {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.tile-row {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  .tile-col {
    display: flex;
    flex-direction: column;
    align-content: stretch;
    .tile-col-card {
      flex-grow: 2;
    }
  }
}

#videoPlayerContainer {
  max-height: 400px;
}
</style>
